<template>
	<div class="main-body" style="padding: 0">
		<div class="inner-wrapper">
			<div class="inner-main">
				<page-header :propButtons="page.buttons" :propConfig="page.header" :propModel="dataModel"></page-header>
				<div class="inner-main-body">
					<div class="card card-featured card-featured-primary">
						<div class="card-body">
							<div class="row">
								<div class="col-md-3">
									<label class="label-modal">Contraseña actual</label>
									<input v-model="user.password_current" type="text" class="form-control form-control-sm" />
									<div v-if="errors_backend.password_current">
										<template v-for="(error, index) in errors_backend.password_current">
											<small class="text-danger" :key="index"> <i class="fas fa-exclamation-circle"></i> {{ error }} </small>
										</template>
									</div>
								</div>
								<div class="col-md-3">
									<label class="label-modal">Nueva contraseña</label>
									<input v-model="user.password" type="text" class="form-control form-control-sm" />
									<div v-if="errors_backend.password">
										<template v-for="(error, index) in errors_backend.password">
											<small class="text-danger" :key="index"> <i class="fas fa-exclamation-circle"></i> {{ error }} </small>
										</template>
									</div>
								</div>
								<div class="col-md-3">
									<label class="label-modal">Confirmar nueva contraseña</label>
									<input v-model="user.password_confirmation" type="text" class="form-control form-control-sm" />
									<div v-if="errors_backend.password_confirmation">
										<template v-for="(error, index) in errors_backend.password_confirmation">
											<small class="text-danger" :key="index"> <i class="fas fa-exclamation-circle"></i> {{ error }} </small>
										</template>
									</div>
								</div>
							</div>
						</div>
						<div class="card-footer text-center">
							<button :disabled="cp_disabled" @click="mtd_save" type="submit" class="btn btn-primary">Cambiar contraseña</button>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
/** Vuex */
import { mapActions, mapGetters } from 'vuex';
export default {
	data() {
		return {
			dataModel: {
				name: 'change-password', //AQUI EN INGLES SINGULAR
				alias: 'Cambiar contraseña',
				icon: 'ri-lock-line',
			},
			page: {
				header: {
					menu: ['Cambiar contraseña'],
				},
				buttons: {
					create: false,
					import: false,
					export: false,
				},
			},
			user: {
				password_current: '',
				password: '',
				password_confirmation: '',
			},
			errors_backend: {},
		};
	},
	computed: {
		...mapGetters(['url_api']),
		cp_disabled: function () {
			let state = true;
			if (this.user.password_current != '' && this.user.password != '' && this.user.password_confirmation != '') {
				state = false;
			}
			return state;
		},
	},
	methods: {
		...mapActions(['update', 'get']),
		mtd_save: function () {
			this.update({
				url: this.url_api + '/accesses/change-password',
				params: this.user,
			})
				.then(() => {
					// eslint-disable-next-line no-undef
					alertify.confirm(
						'Contraseña editada con éxito. Saliendo del sistema ...',
						() => {
							// eslint-disable-next-line no-undef
							this.mtd_salir();
						},
						() => {
							// eslint-disable-next-line no-undef
							this.mtd_salir();
						}
					);
				})
				.catch(errors => {
					this.errors_backend = errors;
				});
		},
		mtd_salir: function () {
			this.get({
				url: this.url_api + '/users/logout',
			})
				.then(() => {
					document.cookie = 'permissions=';
					this.$router.push('/');
					localStorage.clear();
				})
				.catch(() => {});
		},
	},
};
</script>
